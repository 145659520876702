import { DataFrame, MutableVector, PanelData } from '@grafana/data';
import { DatasetComponentOption, ECharts, SeriesOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import 'echarts-gl';
import { TrajectoryCalculationUtility } from 'lib/TrajectoryCalculationUtility';
// import 'echarts-gl/src/util/geometry/';
// import { Grid3DComponent } from 'echarts-gl/components';

import { CrossPlotPanelOptions } from './types';

export default class CrossPlotOptions {
  protected echartInst: ECharts;
  protected panelOptions: CrossPlotPanelOptions;
  // protected xAxis: XAXisComponentOption;
  protected trajSeries: DataFrame[] = [];
  protected xAxis: XAXisComponentOption;
  protected yAxis: YAXisComponentOption[];
  protected series: SeriesOption[];
  constructor(
    echartInst: ECharts,
    data: PanelData,
    panelOptions: CrossPlotPanelOptions,

    setPanelOptions: (options: CrossPlotPanelOptions) => void,
    width: number,
    height: number
  ) {
    this.echartInst = echartInst;
    this.panelOptions = panelOptions;

    this.xAxis = this.initXAxis();
    this.yAxis = this.initYAxis();
    this.series = this.prepareSeries();
    this.echartInst.setOption({
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      series: this.series,
      grid: {
        show: true,
        borderWidth: 2,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: function (pos: number[], params: any, el: any, elRect: any, size: { viewSize: number[] }) {
          var obj = { top: 10 } as any;
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        },
        extraCssText: 'width: 170px',
      },
      axisPointer: {
        type: 'line',
        show: true,
      },
      visualMap: {
        id: 'GR',
        type: 'continuous',
        min: 10,
        max: 30,
        dimension: 'GR (api)',
        seriesIndex: 2,
        seriesName: 'GR (api)',
        itemHeight: height,
        // itemHeight: 500,
        right: 0,
        padding: 0,

        left: 'auto',
        inRange: {
          color: ['blue', 'red', 'yellow'],
        },
      },
      legend: {
        // top: '30%',
        show: false,
      },
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: 0,
          yAxisIndex: 0,
        },
        // {
        //   type: 'inside',
        // },
      ],
    });
  }

  protected initXAxis() {
    this.xAxis = {
      name: this.panelOptions.xAxisProperty,
      type: 'value',
      id: 'x_axis',
      nameLocation: 'middle',
      inverse: this.panelOptions.xAxisProperty === 'tvd',
      axisLine: {
        show: true,
      },
      axisLabel: {},
      min: -0.05,
      max: 0.6,
      axisTick: {
        show: true,
      },
    };
    return this.xAxis;
  }

  protected initYAxis() {
    this.yAxis = [
      {
        name: this.panelOptions.yAxisProperty,
        type: 'value',
        id: 'y_axis',
        nameLocation: 'middle',
        inverse: this.panelOptions.yAxisProperty === 'tvd',
        axisLine: {
          show: true,
        },
        min: 2,
        max: 3,
        axisTick: {
          show: true,
        },
      },
      {
        name: 'GR (api)',
        type: 'value',
        id: 'y_axis2',
        nameLocation: 'middle',
        inverse: this.panelOptions.yAxisProperty === 'tvd',
        axisLine: {
          show: true,
        },
        splitLine: {
          show: false,
        },
        min: 10,
        max: 30,
        axisTick: {
          show: false,

          // inside: true,
        },
        position: 'right',
      },
    ];
    return this.yAxis;
  }

  protected prepareSeries() {
    this.series = [
      {
        type: 'line',
        xAxisId: 'x_axis',
        yAxisId: 'y_axis',
        color: this.panelOptions.curve1Properties?.color?.toString(),
        name: this.panelOptions.curve1Properties?.curveTitle,
        seriesLayoutBy: 'row',
        datasetIndex: 0,
        encode: {
          x: this.panelOptions.xAxisProperty,
          y: this.panelOptions.yAxisProperty,
        },
        showSymbol: false,
      },
      {
        type: 'line',
        xAxisId: 'x_axis',
        yAxisId: 'y_axis',
        color: this.panelOptions.curve2Properties?.color?.toString(),
        name: this.panelOptions.curve2Properties?.curveTitle,
        seriesLayoutBy: 'row',
        datasetIndex: 1,
        encode: {
          x: this.panelOptions.xAxisProperty,
          y: this.panelOptions.yAxisProperty,
        },
        showSymbol: false,
      },
      {
        type: 'line',
        xAxisId: 'x_axis',
        yAxisId: 'y_axis',
        color: this.panelOptions.curve2Properties?.color?.toString(),
        name: 'GR (api)',
        id: 'GR (api)',
        seriesLayoutBy: 'row',
        datasetIndex: 1,
        encode: {
          x: this.panelOptions.xAxisProperty,
          y: this.panelOptions.yAxisProperty,
        },
        showSymbol: false,
      },
    ];
    return this.series;
  }

  setData(data: PanelData, update = true) {
    // console.log(data);
    this.trajSeries = data.series.filter((s) => s.meta?.custom!['dataObjectType'] === 'Trajectory')!;

    let lastAziValueActual = 0;
    if (this.trajSeries.length === 2) {
      //NOTE! assume the first series is "actual!"
      let aziValuesActual = this.trajSeries[0].fields.find((f) => f.name === 'azi')!.values.toArray();
      let dispEwPlanned = this.trajSeries[1].fields.find((f) => f.name === 'dispEw')!.values.toArray();
      let dispNsPlanned = this.trajSeries[1].fields.find((f) => f.name === 'dispNs')!.values.toArray();

      lastAziValueActual = aziValuesActual.length > 0 ? aziValuesActual[aziValuesActual.length - 1] : 0;

      let lastVertSectPlanned = TrajectoryCalculationUtility.CalculateVerticalSection(
        lastAziValueActual,
        dispEwPlanned[dispEwPlanned.length - 1],
        dispNsPlanned[dispNsPlanned.length - 1]
      );
      if (lastVertSectPlanned < 0) {
        //turn 180 degres
        lastAziValueActual = lastAziValueActual + 180;
      }
    }

    let datasets = this.trajSeries.map((tjs) => {
      // let tjs = this.trajSeries[0];
      // let fTvd = tjs.fields.find((f) => f.name === 'tvd')!.values.toArray();
      let fXAxis = tjs.fields.find((f) => f.name === this.panelOptions.xAxisProperty)!.values.toArray();
      let fYAxis = tjs.fields.find((f) => f.name === this.panelOptions.yAxisProperty)!.values.toArray();
      // let seriesData = fTvd.map((tvd, i) => {
      //   return [fDispEW[i], fDispNS[i], tvd];
      // });
      if (this.panelOptions.xAxisProperty === 'vertSect' || this.panelOptions.yAxisProperty === 'vertSect') {
        //vertical section is calculated
        let aziValues = tjs.fields.find((f) => f.name === 'azi')!.values.toArray();
        let dispNsValues = tjs.fields.find((f) => f.name === 'dispNs')!.values.toArray();
        let dispEwValues = tjs.fields.find((f) => f.name === 'dispEw')!.values.toArray();
        let vertSectField = tjs.fields.find((f) => f.name === 'vertSect')!;
        let vertSectValues = aziValues.map((azi, i) => {
          // return TrajectoryCalculationUtility.CalculateVerticalSection(azi, dispEwValues[i], dispNsValues[i]);
          let vertSect = TrajectoryCalculationUtility.CalculateVerticalSection(
            lastAziValueActual,
            dispEwValues[i],
            dispNsValues[i]
          );
          let vertSect2 = Math.sqrt(Math.pow(dispEwValues[i], 2) + Math.pow(dispNsValues[i], 2));

          if (vertSect < 0) {
            vertSect2 = -vertSect2;
          }

          (vertSectField.values as MutableVector).add(vertSect2);
          // (vertSectField.values as MutableVector).set(i, vertSect);
          return vertSect2;
        });

        if (this.panelOptions.xAxisProperty === 'vertSect') {
          fXAxis = vertSectValues;
        }
        if (this.panelOptions.yAxisProperty === 'vertSect') {
          fYAxis = vertSectValues;
        }
      }

      let dimensions: DatasetComponentOption['dimensions'] = [
        {
          name: this.panelOptions.xAxisProperty,
          type: 'number',
        },
        {
          name: this.panelOptions.yAxisProperty,
          type: 'number',
        },
      ];
      let columnar = [fXAxis, fYAxis];
      let dataset: DatasetComponentOption = { dimensions, source: columnar };

      // this.echartInst.setOption({
      //   dataset,
      // });
      return dataset;
    });

    // let s1 = this.trajSeries[0];
    // let fTvd = s1.fields.find((f) => f.name === 'tvd')!.values.toArray();
    // let fDispEW = s1.fields.find((f) => f.name === 'dispEw')!.values.toArray();
    // let fDispNS = s1.fields.find((f) => f.name === 'dispNs')!.values.toArray();
    // let seriesData = fTvd.map((tvd, i) => {
    //   return [fDispEW[i], fDispNS[i], tvd];
    // });

    this.echartInst.setOption({
      dataset: datasets.flat(),
    });

    // console.log(this.echartInst.getZr());
  }
}

import { PanelPlugin, SetFieldConfigOptionsArgs } from '@grafana/data';
import { GraphFieldConfig } from '@grafana/ui';

import { CrossPlotPanelOptions } from './types';
import { CrossPlotPanel } from './CrossPlotPanel';
import { defaultGraphConfig, getGraphFieldConfig } from 'panels/log-plot-panel/copiedFromAlpha75/timeSeriesConfig';
import { LogPlotFieldConfig } from 'panels/log-plot-panel/module';

export interface CrossPlotFieldConfig extends GraphFieldConfig {
  //   fontSize: number;
}

export const defaultCrossPlotFieldConfig: CrossPlotFieldConfig = {
  ...defaultGraphConfig,

  //   fontSize: 16,
};

function getCrossPlotFieldConfig(): SetFieldConfigOptionsArgs<CrossPlotFieldConfig> {
  let fc = getGraphFieldConfig(defaultCrossPlotFieldConfig as LogPlotFieldConfig);
  let oldUseCustomConf = fc.useCustomConfig!;
  fc.useCustomConfig = (builder) => {
    oldUseCustomConf(builder);
  };
  return fc as any;
}

export const plugin = new PanelPlugin<CrossPlotPanelOptions>(CrossPlotPanel)
  .useFieldConfig(getCrossPlotFieldConfig())
  .setPanelOptions((builder) => {
    builder

      .addTextInput({
        path: 'curve1Properties.curveTitle',
        name: 'Plot 1 Title',
        // description: 'Trajectory 1 Title',
        defaultValue: 'Neutron Density',
      })

      .addColorPicker({
        path: 'curve1Properties.color',
        name: 'Plot 1 Color',
        // description: 'Trajectory 1 Color',
        defaultValue: 'blue',
      })

      .addTextInput({
        path: 'curve2Properties.curveTitle',
        name: 'Plot 2 Title',
        // description: 'Trajectory 2 Title',
        defaultValue: '',
      })

      .addColorPicker({
        path: 'curve2Properties.color',
        name: 'Plot 2 Color',
        // description: 'Trajectory 2 Color',
        defaultValue: 'red',
      })

      .addTextInput({
        path: 'xAxisProperty',
        name: 'xAxis',
        defaultValue: 'Neutron (m3/m3)',
      })
      .addTextInput({
        path: 'yAxisProperty',
        name: 'xAxis',
        defaultValue: 'Density (g/cm3)',
      });
  });

import React, { useState, useEffect, useCallback } from 'react';
import { PanelProps } from '@grafana/data';
import { CrossPlotPanelOptions } from './types';
import { InlineField, InlineSwitch, Input } from '@grafana/ui';
import { ECharts } from 'echarts';
import CrossPlotOptions from './CrossPlotOptions';
import ReactECharts from 'echarts-for-react';

interface Props extends PanelProps<CrossPlotPanelOptions> {}
const bottomControlPanelHeight = 100;

const BottomControlPanel = () => {
  const [displayAllData, setDisplayAllData] = useState(true);
  const [min, setMin] = useState<string>('0');
  const [max, setMax] = useState<string>('2000');

  const onDisplayAllSwitchChange = useCallback(
    (e: any) => setDisplayAllData(e.currentTarget.checked),
    [setDisplayAllData]
  );
  const onMaxChange = useCallback((e: any) => setMax(e.target?.value as string), [setMax]);
  const onMinChange = useCallback((e: any) => setMin(e.target?.value as string), [setMin]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
      <InlineField key="bgg" label="Display All Data">
        <InlineSwitch
          key="a"
          value={displayAllData}
          onChange={onDisplayAllSwitchChange}
          label="Display All Data"
          title="Display All Data"
        ></InlineSwitch>
      </InlineField>

      <InlineField key="b" disabled={min === undefined || displayAllData} label="Min Depth">
        <Input value={min ?? ''} label="Min Depth" onChange={onMinChange}></Input>
      </InlineField>
      <InlineField key="c" disabled={displayAllData} label="Max Depth">
        <Input value={max} label="Max Depth" onChange={onMaxChange}></Input>
      </InlineField>
      <InlineField key="e" label="Display Index Range:">
        <div style={{ alignSelf: 'center' }}>{`${min} - ${max} (Available: NA - NA)`}</div>
      </InlineField>
    </div>
  );
};

export const CrossPlotPanel: React.FC<Props> = ({ options, data, width, height, onOptionsChange }) => {
  const [echartInst, setEchartInst] = useState<ECharts | undefined>(undefined);
  const [crossPlotOption, setCrossPlotOption] = useState<CrossPlotOptions | undefined>(undefined);
  // const [aziViewAngle, setAziViewAngle] = useState(options.azimuthViewAngle);

  useEffect(() => {
    if (echartInst !== undefined && crossPlotOption !== undefined) {
      crossPlotOption.setData(data, true);
    }
    // setLoading(data.state === LoadingState.Loading ? true : false);
  }, [echartInst, data, crossPlotOption]);
  return (
    <div style={{ height, width }}>
      <ReactECharts
        option={{}}
        style={{ width, height: height - bottomControlPanelHeight }}
        // showLoading={loading}
        onChartReady={(inst) => {
          setEchartInst(inst);
          setCrossPlotOption(
            new CrossPlotOptions(inst, data, options, onOptionsChange, width, height - bottomControlPanelHeight)
          );
        }}
      />
      <div style={{ height: bottomControlPanelHeight }}>
        <BottomControlPanel />
      </div>
    </div>
  );
};
